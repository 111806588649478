import React from 'react';
import {Box} from '@mui/material';

function sideNavAdmin(props) {
  return (
    <Box>
    <div>sideNavAdmin</div></Box>
  )
}

export default sideNavAdmin