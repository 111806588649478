import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  userId: "",
  userName: "",
  source: "",
  PCRDetails: {
    template: {},
    material: [],
  },
  userDetails: {},
  filter: {
    conditionType: null,
    pcrId: null,
    pcrName: null,
    salesOrganization: null,
    pcrTemplateId: null,
    searchString: null,
    status: null,
    requestedBy: null,
    createdOnFilter: {
      createdOn: null,
      operator: null,
    },
    userId: null,
  },
  tempFilter: {
    conditionType: null,
    pcrId: null,
    pcrName: null,
    salesOrganization: null,
    pcrTemplateId: null,
    searchString: null,
    status: null,
    requestedBy: null,
    createdOnFilter: {
      createdOn: null,
      operator: null,
    },
    userId: null,
  },
  menuPopupanchor: null,
  selectedPcr: "",
  selectedPcrStatus: "",
  pcrCreationNotif: {
    visible: false,
    message: "",
  },
  tempForSelectedPcr: "",
  // actionItemsState: {
  //   revoke: true,
  //   discard: true
  // }

  languageTranslations: {},
};

export const appReducer = createSlice({
  name: "appReducer",
  initialState,
  reducers: {
    setLanguageTranslations: (state, action) => {
      state.languageTranslations=action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setSource: (state, action) => {
      state.source = action.payload;
    },
    setPCRDetails: (state, action) => {
      state.PCRDetails = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
      state.tempFilter.userId = action.payload;
      state.tempFilter.requestedBy = action.payload;
      state.filter.userId = action.payload;
      state.filter.requestedBy = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    settempFilter: (state, action) => {
      state.tempFilter = action.payload;
    },
    setmenuPopUpAnchor: (state, action) => {
      state.menuPopupanchor = action.payload;
    },
    setSelectedPcr: (state, action) => {
      state.selectedPcr = action.payload;
    },
    setPcrCreationNotif: (state, action) => {
      state.pcrCreationNotif = action.payload;
    },
    settempForSelectedPcr: (state, action) => {
      state.tempForSelectedPcr = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    // setselectedpcrStatus: (state,action) => {
    //   state.selectedPcrStatus = action.payload
    // }
    // setActionItemsstate: (state, action) => {
    //   switch (action.type) {
    //     case "SETREVOKE":
    //       state.actionItemsState.revoke = true;
    //       break;
    //     case "NILLREVOKE":
    //       state.actionItemsState.revoke = false;
    //       break;
    //     case "SETDISCARD":
    //       state.actionItemsState.discard = true;
    //       break;
    //     case "NILLDISCARD":
    //       state.actionItemsState.discard = false;
    //       break;
    //   }

    // }
  },
});

export const { setLanguageTranslations, setToken, setPCRDetails, setSource, setUserId, setFilter, settempFilter, setmenuPopUpAnchor, setSelectedPcr, setPcrCreationNotif, setUserName, settempForSelectedPcr, setUserDetails } = appReducer.actions;

export default appReducer.reducer;
