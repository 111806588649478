
import { store } from "../redux/store";
// export const testWrapper = (url, options) => {
//   const updateOptions = { ...options };

//   const token = store.getState().token;

//   if (token.length) {
//     updateOptions.headers = {
//       ...updateOptions.headers,
//       Authorization: `Bearer ${token}`,
//     };

//     return fetch(url, updateOptions);
//   } else {
//     return fetch(url, updateOptions);
//   }
// }


export const fetchWrapper = async (baseurl, options) => {
  let splitUrl = baseurl.split("/");
  let url = baseurl.replace("/" + splitUrl[1], process.env["REACT_APP_" + splitUrl[1]]);
  const updateOptions = { ...options };
  const token = store.getState().token;
  let result = [];
  
  if (token.length)
    updateOptions.headers = {
      ...updateOptions.headers,
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*"
    };
   
  //axios or ajax
  await fetch(url, updateOptions).then((response) => {
    if (response.status >= 400 && response.status < 600) {
      throw new Error("Bad response from server");
    }
    return response;
  }).then(returnedResponse => returnedResponse.json())
    .then((data) => {
      result = data;
    }).catch((error) => {
      console.log(error)
    })
  return result;
}

// export default fetchWrapper;
// export default fetchWrapper;

export const fetchWrapperBlob = async (baseurl, options) => {
  let splitUrl = baseurl.split("/");
  let url = baseurl.replace("/" + splitUrl[1], process.env["REACT_APP_" + splitUrl[1]]);
  const updateOptions = { ...options };
  const token = store.getState().token;
  let result = [];
  if (token.length)
    updateOptions.headers = {
      ...updateOptions.headers,
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*"
    };
  await fetch(url, updateOptions).then((response) => {
    if (response.status >= 400 && response.status < 600) {
      throw new Error("Bad response from server");
    }
    return response;
  }).then(returnedResponse => returnedResponse.blob())
    .then((data) => {
      result = data;
    }).catch((error) => {
      console.log(error)
    })
  return result;
}