import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Divider,Typography } from "@mui/material";
import CherryworkLogoOriginal from "../../assets/CherryworkLogosvg.svg";
import IPMLogo from "../../assets/IPMLogo.svg";
import sapiens from "../../assets/sapiens2.svg";

export default function NotAuthorizedPage() {
  return (
    <>
      <Box flexDirection="row" display="flex" alignItems="center" justifyContent="flex-start" columnGap={2} p={2}>
        <div
          style={{
            height: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={CherryworkLogoOriginal} alt="CherryworkLogo"></img>
        </div>
        <Divider orientation="vertical" sx={{ height: "30px", color: "black" }} />
        <img src={IPMLogo} alt="IPMLogo"></img>
      </Box>
      <Stack display="flex" alignItems="center" justifyContent="center" p={4}>
          <img src={sapiens} alt="IPMLogo"></img>
          <Typography variant="h6" color="#000" fontWeight="bold">Access Denied</Typography>
          <Typography variant="h6" color="#000">Looks like you don't have permission. Please contact Admin</Typography>
      </Stack></>
  );
}
