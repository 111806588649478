import { lazy, Suspense, useRef, useEffect, useState } from "react";
// import PriceBook from './components/PriceBook/PriceBook';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AppBar, Grid, ThemeProvider, Stack } from "@mui/material";
import { customTheme } from "./utility/theme";
import { NavBar, AppHeader } from "./components/SideNavBar/NavBar";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import UserProfile from "./UserProfile.jsx";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import {
  DataMaintenance,
  ManageTemplate,
  ApprovalProcess,
  Roles_Auth,
  SimulationDefinition,
  PriceStrategy,
  EmailConfigIcon,
  LanguageTranslation,
  Min_Max,
  PlanningIcon,
} from "./icons/Icons";
import {
  setToken,
  setUserDetails,
  setUserId,
  setUserName,
  setLanguageTranslations,
} from "./redux/reducers/appReducer";
import "./App.css";
import { fetchWrapper } from "./services/fetchWrapper";
import LoadingApplication from "./components/ExtendedComponents/LoadingApplication";
import CustomIcon from "./components/ExtendedComponents/CustomIcon";

// import CreatePcr from './components/PriceBook/CreatePcr';
// import ChangePricing from './components/PriceBook/ChangePricing';
// import PCRList from './components/PriceBook/pcrList';
const PCRList = lazy(() => import("./components/PriceBook/pcrList"));
const CreatePcr = lazy(() => import("./components/PriceBook/CreatePcr"));
const Dashboard = lazy(() => import("./components/IPMDashboardArtifact"));
const Approval = lazy(() => import("./components/ITMTask/TaskApproval"));
// const Approval =lazy(()=>import('./components/approvalITM'));
const Planning = lazy(() =>
  import("./components/Planning/planningLandingPage")
);
const ChangePricingSAP = lazy(() =>
  import("./components/PriceBook/ChangePricingSAP")
);
const ChangePricing = lazy(() =>
  import("./components/PriceBook/ChangePricing")
);
const MaterialDetails = lazy(() =>
  import("./components/PriceBook/MaterialDetails")
);
const TextRule = lazy(() => import("./components/RuleBasedStrategy/TextRule"));
const PriceReport = lazy(() =>
  import("./components/PriceReport/PriceReportLandingScreen")
);
const Simulation = lazy(() =>
  import("./components/Simulation/SimulationLandingPage")
);
const SentimentAnalyser = lazy(() => import("./components/SentimentAnalyser"));
const AdminConfig = lazy(() =>
  import("./components/adminconfig/AdminConfigLandingPage")
);

const useStyles = makeStyles(() => ({
  subPage: {
    overflow: "overlay",
    maxWidth: "calc(100vw - 84px)",
    // width: 'calc(100vw - 88px)'
  },
}));

function IPMApp({ token, userId, userName, userDetails, keycloak }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [adminMenu, setAdminMenu] = useState(false);
  const [adminAnchor, setAdminAnchor] = useState("");
  const [languageTranslation, setLanguageTranslation] = useState(true);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [userProfileAnchorElement, handleUserProfileAnchorElement] =
    useState(null);
  const [adminConsoleList, setAdminConsoleList] = useState([
    {
      name: "Data_Maintenance",
      displayName: "Data Maintenance",

      icon: <DataMaintenance />,
    },

    {
      name: "Manage_Template",

      displayName: "Manage Template",
      icon: <ManageTemplate />,
    },
    {
      name: "Approval_Process",

      displayName: "Approval Process",
      icon: <ApprovalProcess fill="none" />,
    },
    {
      name: "User_Management",

      displayName: "User Management",
      icon: <Roles_Auth />,
    },

    {
      name: "Simulation_Definition",

      displayName: "Simulation Definition",
      icon: <SimulationDefinition />,
    },
    {
      name: "Price_Strategy",

      displayName: "Price Strategy",
      icon: <PriceStrategy />,
    },
    {
      name: "Email_Configuration",

      displayName: "Email Configuration",
      icon: <EmailConfigIcon />,
    },
    {
      name: "Language_Translation",

      displayName: "Language Translation",
      icon: <LanguageTranslation />,
    },
    // {
    //   name: "Min_&_Max_Threshold",

    //   displayName: "Min & Max Threshold",
    //   icon: <Min_Max fill="none" />,
    // },
    {
      name: "Role_Management",
      displayName: "Role Management",

      icon: <DataMaintenance />,
    },
    {
      name: "Scheduler_Config",
      displayName: "Scheduler Config",

      icon: <EventRepeatIcon />,
    },
    {
      name: "DashboardConfig",
      displayName: "Dashboard Configuration",
      icon: <BubbleChartIcon />,
    },
  ]);
  dispatch(setToken(token));
  dispatch(setUserId(userId));
  dispatch(setUserName(userName));
  dispatch(setUserDetails(userDetails));

  const getLangTranslationData = (lang) => {
    fetchWrapper(
      "/WorkUtilsServices/translation/v1/getPropertiesTranslations?applicationName=IPM&elementName=UI&languageCode=" +
        lang
    ).then((data) => {
      let temp = {};
      data.data.forEach((ele) => {
        temp = { ...temp, [ele.propertyName]: ele };
      });
      dispatch(setLanguageTranslations(temp));
      setLanguageTranslation(false);
    });
  };
  useEffect(() => {
    getLangTranslationData("EN");
  }, []);

  const fnMouseEnter = (item) => {
    if (item === "Admin Config") {
      setAdminMenu(true);
      // props.setAdminAnchor();
    } else {
      setAdminMenu(false);
    }
  };
  const fnMouseLeave = () => {
    setAdminMenu(false);
    setAdminAnchor(null);
  };

  return languageTranslation ? (
    <LoadingApplication
      status="loading"
      variant="determinate"
      progressValue={10}
      message="Please wait ..."
    />
  ) : (
    <ThemeProvider theme={customTheme}>
      <Router>
        <div style={{ height: "100vh", overflow: "hidden" }}>
          <AppHeader
            setShowUserProfile={setShowUserProfile}
            showUserProfile={showUserProfile}
            handleUserProfileAnchorElement={handleUserProfileAnchorElement}
            userProfileAnchorElement={userProfileAnchorElement}
          />
          <div className="wbMainPageHeight">
            <Stack direction="row" sx={{ height: "100%" }}>
              <NavBar
                fnMouseLeave={() => fnMouseLeave()}
                fnMouseEnter={(title) => fnMouseEnter(title)}
              />
              {adminMenu && (
                <Box
                  display="box"
                  sx={{
                    zIndex: "200",
                    height: "100%",
                    width: "15%",
                    left: "5rem",
                    position: "fixed",
                    backgroundColor: (theme) => theme.palette.background.paper,
                    borderBottom: "1px solid #D1D1D1",
                  }}
                  onMouseOver={() => fnMouseEnter("Admin Config")}
                  onMouseLeave={() => fnMouseLeave()}
                >
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    {adminConsoleList.map((ele) => (
                      <ListItem sx={{ padding: "0.4rem 0 0.4rem 0" }}>
                        <NavLink
                          to={`/adminConfig/${ele.name}`}
                          style={{ textDecoration: "none", width: "100%" }}
                        >
                          <Stack direction="row">
                            <ListItemAvatar>
                              <Avatar>{ele.icon}</Avatar>
                            </ListItemAvatar>
                            <ListItemText secondary={ele.displayName} />
                          </Stack>
                        </NavLink>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
              <UserProfile
                keycloak={keycloak}
                userDetails={userDetails}
                setShowUserProfile={setShowUserProfile}
                showUserProfile={showUserProfile}
                handleUserProfileAnchorElement={handleUserProfileAnchorElement}
                userProfileAnchorElement={userProfileAnchorElement}
              />
              <div className={`wbSetHeight100 `}>
                <Suspense fallback={<>...</>}>
                  <Routes>
                    {/* <Route path="/" exact element={<PCRList />}></Route> */}
                    <Route
                      path="/pricebook"
                      exact
                      element={<PCRList />}
                    ></Route>
                    <Route
                      path="/pricebook/cp/SAP/:Id"
                      exact
                      element={<ChangePricingSAP />}
                    ></Route>
                    <Route
                      path="/pricebook/cp/FF/:Id"
                      exact
                      element={<ChangePricing />}
                    ></Route>
                    <Route
                      path="/pricebook/cp/:pcrId/:materialNo"
                      exact
                      element={<MaterialDetails />}
                    ></Route>
                    <Route
                      path="/dashboard"
                      exact
                      element={<Dashboard />}
                    ></Route>
                    <Route
                      path="/adminConfig/:title"
                      exact
                      element={<AdminConfig />}
                    ></Route>
                    <Route
                      path="/adminConfig"
                      exact
                      element={<AdminConfig />}
                    ></Route>
                    <Route
                      path="/approval"
                      exact
                      element={<Approval />}
                    ></Route>
                    {/* <Route path="/approval" exact element={<Approval />}></Route> */}
                    {/* <Route path="/sentimentAnalyser" exact element={<SentimentAnalyser />}></Route> */}
                    <Route
                      path="/simulation"
                      exact
                      element={<Simulation />}
                    ></Route>
                    <Route
                      path="/pricereport"
                      exact
                      element={<PriceReport />}
                    ></Route>
                    <Route
                      path="/rulebasedstrategy"
                      element={<TextRule />}
                    ></Route>
                    <Route
                      path="/pricebook/createPCR"
                      element={<CreatePcr />}
                    ></Route>
                    <Route path="/planning" element={<Planning />}></Route>
                    <Route
                      path="/planning/:title"
                      exact
                      element={<Planning />}
                    ></Route>
                    <Route
                      path="*"
                      element={<Navigate to="/pricebook" replace />}
                    />
                  </Routes>
                </Suspense>
              </div>
            </Stack>
          </div>
        </div>
        {/* </Grid> */}
      </Router>
    </ThemeProvider>
  );
}

export default IPMApp;
