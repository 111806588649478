import React, { useState,useEffect } from "react";
import Keycloak from "keycloak-js";
import "./index.css";
import IPMApp from "./IPMApp";
import NotAuthorizedPage from "./components/NoAuth/NotAuthorizedPage.jsx";
import LoadingApplication from "./components/ExtendedComponents/LoadingApplication";

const Secured = () => {

  const [action, setAction] = useState("checkAuthentication");
  const [authenticated, setAuthenticated] = useState("loading");
  const [authorised, setAuthorized] = useState("loading");
  const [keycloak, setKeycloak] = useState("null");
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const keycloak = new Keycloak(
      window.location?.hostname === "localhost"
        ? "/keycloak_local.json"
        : window.location?.hostname.includes("ipmqa")
        ? "/keycloak_qa.json"
        : "/keycloak_dev.json"
    );
    keycloak
      .init({
        onLoad: "login-required",
        checkLoginIframe: false,
      })
      .then((authenticated) => {
        setAction(authenticated ? "checkAuthorisation" : "checkAuthentication");
        setAuthenticated(authenticated);
        setAuthorized("loading");
        setKeycloak(keycloak);

        const bearer = "Bearer " + keycloak.token;
        const headers = {
          Authorization: bearer,
        };
        let url = process.env["REACT_APP_WorkAccessServices"].concat(
          "/rest/api/v1/applications/entitiesAndActivities?applicationId=55"
        );
        fetch(url, { headers: headers })
          .then((res) => res.json())
          .then((response) => {
            if (response.data) {
              setUserData(response.data);
              setAction(
                authenticated ? "checkAuthorisation" : "checkAuthentication"
              );
              setAuthenticated(authenticated);
              setAuthorized("authorized");
              setKeycloak(keycloak);
            }
            else{
                setAuthorized("notauthorized"); 
            }
          });
      });
  }, []);

  if (action === "checkAuthentication") {
    if (authenticated === "loading") {
      {
        //   Check for authorisation
        // Step 1 progress 30%
        return (
          <LoadingApplication
            status="loading"
            variant="determinate"
            progressValue={10} //2nd step
            message="Please wait while we sign you in..."
          />
        );
      }
    } else {
      // Invalid user logged in Progress 100%
      return (
        <LoadingApplication
          status="failure"
          variant="determinate"
          progressValue={100}
          message="Please check your credentials and try again."
        />
      );
    }
  }

  if (action === "checkAuthorisation") {
    if (authorised === "loading") {
      //   Check for authorisation Progress 60%
      return (
        <LoadingApplication
          status="loading"
          variant="determinate"
          progressValue={60} //2nd step
          // message="Please wait while we sign you in..."
          message="Almost there, fetching your privileges..."
        />
      );
    } else if (authorised === "authorized") {
      //   Authorised user
      return (
        <IPMApp
          token={keycloak?.token}
          userId={userData?.user_id}
          userName={userData?.displayName}
          userDetails={userData}
          keycloak={keycloak}
        />
      );
    } else {
      return (
        <NotAuthorizedPage/>
      );
    }
  }
  
};

export default Secured;
