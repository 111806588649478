import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
// import BookCloseIcon from '../../icons/BookClose';
import { Workspace,DashBoardIcon, SimulationIcon, SentimentAnalyserIcon, PriceReportIcon, AdminIcon, RuleBasedStIcon, PriceBookIcon } from "../../icons/Icons";
// import { SidebarData } from './SidebarData';
import "./NavBar.css";
import PsychologyIcon from "@mui/icons-material/Psychology";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import { AppBar, Stack } from "@mui/material";
import { store } from "../../redux/store";
// import { IconContext } from 'react-icons/lib';
import { makeStyles } from "@mui/styles";
import CustomAvatar from "../ExtendedComponents/CustomAvatar";
import CherryworkLogo from "../../assets/CherryworkLogo.svg";
import IPMLogo from "../../assets/IPMLogo.svg";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CustomIcon from "../ExtendedComponents/CustomIcon";
import sideNavAdmin from "./sideNavAdmin";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#ffffff",
    height: 52,
    boxShadow: "0px 2px 4px rgba(192, 192, 192, 0.25)",
    overflow: "hidden",
  },
  logoContainer: {
    display: "flex",
  },
  cwLogo: {
    width: 72,
    display: "flex",
    justifyContent: "center",
  },
  headerIcons: {
    fontSize: 24,
    color: "#757575",
  },
  headerAvatarStyle: {
    width: 32,
    height: 32,
    fontSize: 12,
    // marginRight: theme.spacing(1.5),
  },
  notifHeader: {
    height: "38px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #E9E9E9",
  },
}));

export const NavBar = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  let currentPath = location.pathname;
  const divRef = React.useRef();
  // const [currentPath, setcurrentPath] = useState(location.pathname);
  // useEffect(() => {
  //     setcurrentPath(location.pathname)
  // }, [location.pathname])
  const getIconColor = (pathname) => {
    return currentPath.includes(pathname) ? "#007AD4" : "#424242";
  };

  const getIconFill = (pathname) => {
    return currentPath.includes(pathname) ? "#007AD4" : "none";
  };
  const userDt = useSelector((state) => state.userDetails);
  const SidebarData = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: <DashBoardIcon size={28} style={{ padding: "10px" }} color={getIconColor("dashboard")} fill={getIconFill("dashboard")} />,
      cName: "nav-text",
    },
    {
      title: "Pricing Proposal",
      path: "/pricebook",
      icon: <PriceBookIcon style={{ padding: "10px" }} color={getIconColor("pricebook")} fill={getIconFill("pricebook")} />,
      cName: "nav-text",
    },

    {
      title: "Simulation",
      path: "/simulation",
      icon: <SimulationIcon style={{ padding: "10px" }} color={getIconColor("simulation")} fill={getIconFill("simulation")} />,
      cName: "nav-text",
    },
    {
      title: "Approval",
      path: "/approval",
      icon: <Workspace style={{ padding: "10px" }} color={getIconColor("approval")} fill={getIconFill("approval")} />,
      cName: "nav-text",
    },
    // {
    //     title: "Approval",
    //     path: "/approval",
    //     icon: <DashBoardIcon size={28} style={{ padding: "10px" }} color={getIconColor("dashboard")} fill={getIconFill("dashboard")}/>,
    //     cName: 'nav-text',
    // },
    {
      title: "Price Report",
      path: "/pricereport",
      icon: <PriceReportIcon style={{ padding: "10px" }} color={getIconColor("pricereport")} fill={getIconFill("pricereport")} />,
      cName: "nav-text",
    },
    {
      title: "Rule Based Strategy",
      path: "/rulebasedstrategy",
      icon: <RuleBasedStIcon style={{ padding: "10px" }} color={getIconColor("rulebasedstrategy")} fill={getIconFill("rulebasedstrategy")} />,
      cName: "nav-text",
    },

    {
      title: "Admin Config",
      path: "/adminConfig",
      icon: <AdminIcon style={{ padding: "10px" , fill:getIconColor("adminConfig") }}  />,
      cName: "nav-text",
    },
    {
      title: "Planning",
      path: "/planning",

      icon: <PsychologyIcon />,
      cName: "nav-text",
    },
    // ,{
    //     title: "Sentiment Analyser",
    //     path: "/sentimentAnalyser",
    //     icon: <SentimentAnalyserIcon style={{ padding: "10px" }} color={getIconColor("sentimentAnalyser")} fill={getIconFill("sentimentAnalyser")}/>,
    //     cName: 'nav-text'
    // }
  ];
  const [roles, setRoles] = useState({ admin: 0, PricingManager: 0, PricingApprover: 0 });
  useEffect(() => {
    let temproles = { ...roles };
    userDt.roles.forEach((ele) => {
      if (ele === "Admin") temproles.admin = 1;
      if (ele === "Pricing Manager") temproles.PricingManager = 1;
      if (ele === "Pricing Approver") temproles.PricingApprover = 1;
    });
    setRoles(temproles);
  }, []);

  return (
    <>
      {/* <IconContext.Provider value={{ color: "#000000" }}> */}
      <List sx={{ maxWidth: "82px", boxShadow: "0px 8px 24px rgb(192 192 192 / 25%)", maxHeight: "100h", paddingTop: "1rem", background: (theme) => theme.palette.background.paper }}>
        <Stack sx={{ width: "100%" }}>
          {SidebarData.map((item, index) => {
            if (roles.admin === 1) {
              return (
                <ListItem key={index} alignItems="center">
                  <NavLink to={item.path} style={{ textDecoration: "none" }}>
                    <Stack sx={{ alignItems: "center", justifyContent: "space-between" }} onMouseOver={() => props.fnMouseEnter(item.title)}>
                      {item.icon}
                      {/* <Typography sx={{ textAlign: "center", color: currentPath.includes(item.path) ? "#007AD4" : "#424242" }} variant="h5"> */}
                      <p style={{ textAlign: "center", fontSize: "12px", color: currentPath.includes(item.path) ? "#007AD4" : "#424242", margin: 0 }}> {item.title} </p>
                      {/* </Typography> */}
                    </Stack>
                  </NavLink>
                </ListItem>
              );
            }
            if (roles.admin === 0 && item.title !== "Admin Config") {
              return (
                <ListItem key={index} alignItems="center">
                  <NavLink to={item.path} style={{ textDecoration: "none" }}>
                    <Stack sx={{ alignItems: "center", justifyContent: "space-between" }}>
                      {item.icon}
                      {/* <Typography sx={{ textAlign: "center", color: currentPath.includes(item.path) ? "#007AD4" : "#424242" }} variant="h5"> */}
                      <p style={{ textAlign: "center", fontSize: "12px", color: currentPath.includes(item.path) ? "#007AD4" : "#424242", margin: 0 }}> {item.title} </p>
                      {/* </Typography> */}
                    </Stack>
                  </NavLink>
                </ListItem>
              );
            }
          })}
        </Stack>

        {/* <Popover
              sx={{zIndex:'1'}}
  
  open={props.adminMenu}
  anchorReference="anchorPosition"
  anchorPosition={{ top: 200, left: 400 }}
   anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}
><p>
  The content of the Popover.</p>
</Popover>      */}
      </List>

      {/* </nav> */}
      {/* </IconContext.Provider> */}
    </>
  );
};

export const AppHeader = (props) => {
  const classes = useStyles();
  const userName = useSelector((state) => state.userName);

  return (
    <AppBar position="sticky" elevation={1} color="default" sx={{ backgroundColor: (theme) => theme.palette.background.navbar }} className={classes.header}>
      <Grid zeroMinWidth item container xs={12} direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={8} container direction="row" justifyContent="flex-start" alignItems="center" style={{ display: "flex" }}>
          <div className={classes.cwLogo}>
            <img src={CherryworkLogo} alt="CherryworkLogo"></img>
          </div>
          <img src={IPMLogo} alt="IPMLogo"></img>
        </Grid>
        <Grid item container xs={4} direction="row" justifyContent="flex-end" alignItems="center" spacing={1} pr={1}>
          <Grid item>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: (theme) => theme.palette.text.heading,
              }}
            >
              Welcome {userName}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              disableRipple
              size="small"
              sx={{
                ":hover": {
                  bgcolor: "transparent",
                },
              }}
            >
              <CustomIcon
                fontSize="inherit"
                icon={"IcoMoonIcon.IoMdNotificationsOutline"}
                className={classes.iconStyle}
                // style={{ padding: "8px" }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              disableRipple
              size="small"
              sx={{
                ":hover": {
                  bgcolor: "transparent",
                },
              }}
            >
              <CustomIcon
                fontSize="inherit"
                icon={"AntIcon.AiOutlineSetting"}
                className={classes.iconStyle}
                // style={{ padding: "8px" }}
              />
            </IconButton>
          </Grid>

          <Grid
            item
            sx={{
              "& .MuiAvatar-root": {
                width: "24px",
                height: "24px",
                fontSize: "12px",
              },
            }}
          >
            <CustomAvatar
              // className={classes.headerAvatarStyle}
              onClick={(event) => { props.setShowUserProfile(true); props.handleUserProfileAnchorElement(event.currentTarget) }}
              src={""}
              name={userName}
              sx={{ bgcolor: "#C3E6FF", color: "#424242" }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: "none",
            flexGrow: 1,
          }}
        >
          <Grid zeroMinWidth item container direction="row" justifyContent="space-between" alignItems="center">
            <Grid className={classes.search} item xs={11} sm={11} md={5} lg={4}></Grid>
            <Grid item container xs={1} sm={1} md={5} lg={8} direction="row" justifyContent="flex-end" alignItems="center" spacing={1}></Grid>
          </Grid>
        </div>
      </Grid>
    </AppBar>
  );
};
