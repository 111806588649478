import { IconButton, Popover, Button, ClickAwayListener } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Avatar from "@mui/material/Avatar";
import CustomIcon from "./components/ExtendedComponents/CustomIcon.jsx";
const UserProfile = (props) => {
    let navigate = useNavigate();
    const fnSignOut = () => {
        navigate("/");
        props.setShowUserProfile(false);
        props.handleUserProfileAnchorElement(null)
        props.keycloak.logout();
     
    }

    return (
        <Popover
            placement="bottom"
            open={props.showUserProfile}
            anchorEl={props.userProfileAnchorElement}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            modifiers={{
                flip: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: "true",
                    boundariesElement: 'scrollParent'
                },
            }}
        >
            <ClickAwayListener onClickAway={() => props.setShowUserProfile(false)}>
                <div style={{
                    padding: '0.5rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    <Avatar name={props.userDetails?.displayName} />

                    <p style={{ margin: '0.2rem', fontSize: '1rem' }}>{props.userDetails?.displayName}</p>
                    <p style={{
                        margin: '0.2rem', color: '#999A96',
                        fontSize: '0.875rem'
                    }}>{props.userDetails?.emailId}</p>
                    <Button  onClick={() => props.setShowUserProfile(false)} className="styleButton" style={{ color: '#CB4747', fontWeight: '500', fontSize: '0.8rem' }}>Manage Account</Button>
                    <Button style={{ color: '#3026B9', fontWeight: '600', fontSize: '0.8rem' }} startIcon={<CustomIcon style={{ width: '1.2rem', height: '1.2rem' }} icon={"MaterialIcon.MdLogout"} />} onClick={fnSignOut} className="styleButton">Sign Out</Button>
                </div>
            </ClickAwayListener>
        </Popover >
    )
}
export default UserProfile;